html.dark {
  filter: invert(1);
}

.dot.dark {
  filter: invert(100%);
}

#gewusst.dark,
#nichtGewusst.dark,
#überspringen.dark,
#addAns.dark,
#saveCard.dark,
#deleteCard.dark,
code.dark,
#deleteSet.dark,
#neuesSet.dark,
#logoutLink.dark {
  filter: invert(100%);
}

.listCard.dark, .editCard.dark {
  background-color: #e8e8e8;
}

html,
body {
  min-height: 100vh;
}

#content {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .buttonCard {
    flex-wrap: wrap;
  }
}

.navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

#liste, #abfrage, #addCard {
  padding: 0.5rem 1rem;
}

.listCard {
  background-color: #fff;
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 5px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
}

.listCard h5 {
  font-family: Fira Code;
  font-variant: all-small-caps;
}

.listCard h3 {
  text-transform: uppercase;
  word-break: break-word;
  hyphens: auto;
}

.dot {
  /* background-color:  #e9464a, #F8D154 ,#79d375; */
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  top: 15px;
  right: 15px;
  position: absolute;
  cursor: pointer;
}

.editCard {
  height: 21px;
  width: 15px;
  display: inline-block;
  bottom: 12px;
  right: 18px;
  position: absolute;
  background: white;
  cursor: pointer;
}

.editCard img {
  height: 100%;
  vertical-align: top;
}

.mjx-chtml {
  outline: none;
}

.ans {
  border-bottom: solid #e6e6e6 0.5px;
  margin-bottom: 10px;
}

.listHead {
  cursor: help;
  width: 90%;
}

#gewusst, #nichtGewusst, #überspringen {
  margin: 0.5rem 0.5rem 15px 0.5rem;
}

img[title=Aufgabenblatt], img[title=markdown] {
  max-width: 100%;
  max-height: 400px;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.btn-outline-primary:hover {
  color: #007bff;
  border-color: #007bff;
  background-color: inherit;
}

.btn-outline-success:hover {
  color: #28a745;
  border-color: #28a745;
  background-color: inherit;
}

.btn-outline-danger:hover {
  color: #dc3545;
  border-color: #dc3545;
  background-color: inherit;
}

.btn-outline-dark:hover {
  color: #343a40;
  border-color: #343a40;
  background-color: inherit;
}

code {
  font-family: Fira Code;
}

select {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('../images/arrow_down.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.25em auto, 100%;
}

select::-ms-expand {
  display: none;
}

select:hover {
  border-color: #888;
}

select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

select option {
  font-weight: normal;
}

::placeholder {
  color: #00000057 !important;
  opacity: 0.2;
}
